import { navigate } from 'gatsby-plugin-intl';
import useSearchParam from "react-use/esm/useSearchParam";
import { useUri } from '@/common/hooks/uri-hook';

function usePostPreviewRedirect(postId) {
  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  var getPreviewToken = useSearchParam('token') || '';
  var getXCraftPreview = useSearchParam('x-craft-live-preview') || '';

  if (getPreviewToken && getXCraftPreview) {
    navigate(uriHelper.getPostPreviewUrl() + "?token=" + getPreviewToken + "&x-craft-live-preview=" + getXCraftPreview + "&postId=" + postId);
  }
}

export { usePostPreviewRedirect };