import { useStaticQuery } from 'gatsby';
import React from 'react';
import { Content } from '@/common/components/content';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { usePostPreviewRedirect } from '@/common/hooks/post-preview-redirect-hook';
import { mapMaybe } from '@/common/utils/map-maybe';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "3269390628";

var NotFoundPage = function NotFoundPage() {
  var _data$cms, _data$cms$notFoundPag, _data$cms$notFoundPag2;

  var data = useStaticQuery(query);
  var attributes = data === null || data === void 0 ? void 0 : (_data$cms = data.cms) === null || _data$cms === void 0 ? void 0 : (_data$cms$notFoundPag = _data$cms.notFoundPage) === null || _data$cms$notFoundPag === void 0 ? void 0 : (_data$cms$notFoundPag2 = _data$cms$notFoundPag.data) === null || _data$cms$notFoundPag2 === void 0 ? void 0 : _data$cms$notFoundPag2.attributes;
  var pageContent = attributes === null || attributes === void 0 ? void 0 : attributes.content;
  var pageTitle = attributes === null || attributes === void 0 ? void 0 : attributes.title;
  usePostPreviewRedirect();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: pageTitle !== null && pageTitle !== void 0 ? pageTitle : '',
    meta: [{
      name: 'robots',
      content: 'noindex'
    }]
  }), pageContent ? ___EmotionJSX(Content, {
    contentBlocks: mapMaybe(pageContent)
  }) : null);
};

NotFoundPage.displayName = "NotFoundPage";
export { NotFoundPage as default };